import type {
  ApplicationStatus,
  ISocureFields,
  ISocureFraud,
  Workflow
} from "@/models/applications";
import type { ConditionType, FinancialProduct } from "@/models/common";
import { NoteType } from "@/enums/notes";
import type { Role } from "@/models/options";
import type {
  IDoDraggable,
  DataOrchestrationMasterStatusType,
  DataOrchestrationTemplateRePullPeriod,
  TimeMeasure,
  DataOrchestrationOutcome
} from "@/models/orchestration";

import type { ScorecardType } from "@/models/scorecards";
import type { IUser } from "@/models/users";
import type { IDnbLevel, IExperianLevel } from "@/models/businessCredit";
import type {
  FieldOption,
  StageCategoryType,
  WorkflowCategory
} from "@/models/workflows";
import i18n from "@/i18n";
import type { ContractType } from "@/models/contractBuilder";
import type { AvatarTheme } from "@/components/ui/LFAvatar.vue";
import type { Report } from "@/models/commercialData/Codat";
import uniq from "lodash/uniq";

export const LOADER_SPINNER_TYPE = "line-scale";
export const LOADER_DURATION = "0.6";

export const DEFAULT_PER_PAGE = 100;
export const DEFAULT_PAGE = 1;

export const PDF_SERVICES_NAMES = {
  RISK_INFORM_REPORT_PERSON: "RiskInformReport_person",
  RISK_INFORM_REPORT_BUSINESS: "RiskInformReport_business",
  CLEAR_ID_CONFIRM_PERSON: "ClearIdConfirm_person",
  CLEAR_KYB_REPORT: "Clear_RiskInformBusinessReport",
  EXPERIAN_BOP: "ExperianBop"
} as const;

export const PUBLIC_PAGES = [
  "Login",
  "Register",
  "ForgotPassword",
  "ResetPassword",
  "CreatePassword",
  "RenewPassword",
  "ClosingDocs"
];

export const SUPER_ADMIN: Role = "super admin";
export const ADMIN: Role = "admin";
export const UNDERWRITER: Role = "underwriter";
export const FUNDING_ADVISOR: Role = "funding advisor";
export const CLIENT_ADMIN: Role = "client admin";
export const CLIENT_UNDERWRITER: Role = "client underwriter";
export const USER: Role = "user";
export const FUNDER_ADMIN: Role = "funder admin";
export const FUNDER_USER: Role = "funder user";
export const ANALYST: Role = "analyst";
export const CLIENT_ANALYST: Role = "client analyst";
export const CLIENT_FUNDING_ADVISOR: Role = "client funding advisor";

export const LENDFLOW_ROLE_GROUP: Role[] = [
  SUPER_ADMIN,
  ADMIN,
  UNDERWRITER,
  FUNDING_ADVISOR,
  ANALYST
];
export const FUNDER_ROLE_GROUP: Role[] = [FUNDER_ADMIN, FUNDER_USER];
export const CLIENT_ROLE_GROUP: Role[] = [
  USER,
  CLIENT_ANALYST,
  CLIENT_FUNDING_ADVISOR,
  CLIENT_ADMIN,
  CLIENT_UNDERWRITER
];

export const NOT_FA_ROLE_GROUP: Role[] = [
  SUPER_ADMIN,
  ADMIN,
  UNDERWRITER,
  ANALYST,
  ...CLIENT_ROLE_GROUP
];

export const ROLES_MAP: Record<string, string> = {
  [SUPER_ADMIN]: "lendflow super admin",
  [ADMIN]: "lendflow admin",
  [UNDERWRITER]: "lendflow processor",
  [FUNDING_ADVISOR]: "lendflow onboarding",
  [CLIENT_ADMIN]: "admin",
  [CLIENT_UNDERWRITER]: "processor",
  [USER]: "viewer",
  [ANALYST]: "lendflow analyst",
  [CLIENT_ANALYST]: "analyst",
  [CLIENT_FUNDING_ADVISOR]: "onboarding"
} as const;

export const INVOICING_METHODS = {
  1: "Email",
  2: "Mail",
  3: "Fax"
};

export const CONTACT_METHODS = {
  1: "By Email",
  2: "By Phone",
  3: "By Text Message"
};

export const TERM_LENGTH_OPTIONS = {
  Daily: "days",
  Weekly: "weeks",
  Monthly: "months",
  Biweekly: "biweekly"
};

export const SIGNED_DOC_FILE_TYPE = "signed_application_doc";
export const BANK_STATEMENT_FILE_TYPE = "bank_statement";

export const STIPS_ALLOWED_FILE_TYPES = [
  "gif",
  "jpeg",
  "jpg",
  "png",
  "pdf",
  "xls",
  "xlsx",
  "doc",
  "docx",
  "heic"
];

export const IMAGE_TYPES = ["gif", "jpeg", "jpg", "png", "heic"];
export const ALLOWED_FILES_TYPES = [
  ...IMAGE_TYPES,
  "xls",
  "pdf",
  "doc",
  "docx",
  "xlsx"
];

export const CFA_DOCUMENT_FILE_TYPES = ["json", "xlsx"];
export const API_REPORT_FILE_TYPES = ["csv", "xlsx"];
export const PDF_JSON_RESPONSE_FILE_TYPES = ["pdf", "json"];
export const JSON_CSV_RESPONSE_FILE_TYPES = ["json", "csv"];

export enum FILE_TYPES {
  json = "json"
}

export const FIELD_OPTIONS = {
  required: "required",
  hidden: "hidden",
  signed_application: "signed_application"
} as Record<string, FieldOption>;

export const PHONE_VALIDATION_MASK = "['(###) ###-####', '+# (###) ###-####']";
export const BANK_ACCOUNT_MASK = "**** **** ****";

export const STATUS_UNDERWRITING = 1;
export const STATUS_PLACEMENT = 2;
export const STATUS_OFFER_OUT = 3;
export const STATUS_CLOSING = 4;
export const STATUS_DEAD = 6;

export const PLACEMENT_STATUS_DECLINED = 2 as const;
export const DECLINED_PLACEMENT_STATUS = "Declined";

export const PRODUCT_TYPE_ARLOC = "1";
export const PRODUCT_TYPE_LINE_OF_CREDIT = "2";
export const PRODUCT_TYPE_RECEIVABLE_PURCHASE = "3";
export const PRODUCT_TYPE_TERM_LOAN = "4";
export const PRODUCT_TYPE_EQUIPMENT_FINANCING = "5";
export const PRODUCT_TYPE_ERC = "9";

export const ORDERED_PRODUCT_TYPES = [
  PRODUCT_TYPE_TERM_LOAN,
  PRODUCT_TYPE_LINE_OF_CREDIT,
  PRODUCT_TYPE_RECEIVABLE_PURCHASE,
  PRODUCT_TYPE_EQUIPMENT_FINANCING,
  PRODUCT_TYPE_ARLOC,
  PRODUCT_TYPE_ERC
] as const;

export const EQUIPMENT_FINANCING = "Equipment Financing";
export const RECEIVABLES_PURCHASE = "Receivables Purchase";

export const CURRENCY_ICON = {
  GBP: "gbp-rec",
  US: "usa-rec"
};

export const PRODUCT_TYPES = {
  arloc: 1,
  "line of credit": 2,
  "receivables purchase": 3,
  "term loan": 4,
  "equipment financing": 5,
  erc: 9
};

export const PAYMENT_PERIOD = {
  3: 1, // monthly
  4: 2.08, // biweekly
  2: 4.16, // weekly
  1: 20.8 //daily
};

// commercial data - start
export const COMMERCIAL_DATA_STATUS_SUCCESS = "Success";
export const COMMERCIAL_DATA_STATUS_NOT_STARTED = "Not yet started";
export const COMMERCIAL_DATA_STATUS_STARTED = "Started";
export const COMMERCIAL_DATA_STATUS_NO_SERVICE_LOG = "No service log.";
export const NO_BUSINESS_FOUND_MESSAGE = "no business found";
export const NO_RESULTS_FOUND_MESSAGE = "no results found";
export const NO_MATCH_FOUND_MESSAGE = "no match found";
export const NO_SEARCH_RESULTS_FOUND_MESSAGE = "no search results found";
export const NO_CONTACT_CARD_RESULTS_FOUND_MESSAGE =
  "No contact card results found.";
export const NO_EMAIL_SEARCH_RESULTS_FOUND_MESSAGE =
  "No email search results found.";
export const COULD_NOT_BE_FOUND_MESSAGE = "could not be found";
export const SEARCH_IN_PROGRESS_MESSAGE = "search in progress";
export const PLAID_CREDENTIALS_CHANGED_MESSAGE =
  "The bank credentials have changed. Generate a new Plaid connection link for the applicant.";
// commercial data - end

export const STATUS_SQL_ERROR = "sql";

export const WIDGET_IDS = {
  LEAD_FUNDING: 1,
  DOCUSIGN: 2,
  PLAID: 3,
  CREDIT_SCORE: 4,
  DOCUSIGN_PLAID: 5,
  LEAD_CAPTURE: 13
};

export const STIP_TYPE = {
  OFFER: 1,
  DEAL: 2
} as const;

export const PRODUCT_TYPE: Record<string, FinancialProduct> = {
  ARLOC: "ARLOC",
  EQUIPMENT_FINANCING: "Equipment Financing",
  LINE_OF_CREDIT: "Line of credit",
  RECEIVABLES_PURCHASE: "Receivables Purchase",
  TERM_LOAN: "Term loan",
  ERC: "ERC"
};

export const regexPercentageRegex = new RegExp(
  "rate|commission|interest|initial_draw_fee|subsequent_draw_fee"
);

export const HISTORY_SECTIONS = {
  DEALS: "deals",
  ADVANCED_SEARCHES: "advancedSearches"
} as const;

export const OTHER_TERMS_ID = "3";

export const COLORS = [
  "6, 214, 160", // green (status success) [0]
  "55, 185, 214", // turquoise [1]
  "255, 89, 94", // red (status error) [2]
  "224, 134, 211", // purple [3]
  "55, 105, 214", // dark blue [4]
  "255, 159, 4", // orange (status warning) [5]
  "39, 130, 63", // dark green [6]
  "154, 69, 185", //dark purple [7]
  "130, 39, 50", // maroon [8]
  "30, 30, 30", // charcoal [9]
  "105, 96, 236", // blue lotus [10]
  "114, 140, 0", // venom green [11]
  "127, 70, 44", // sepia [12]
  "189, 30, 45", // cranberry [13]
  "196, 129, 137", // pink bow [14]
  "134, 141, 156", // grey [15]
  "255, 204, 0", // secondary orange 1 [16]
  "44, 146, 255", // blue [17]
  "233, 37, 215", // pink [18]
  "0, 226, 64", // secondary green 2 [19]
  "130, 67, 245", // secondary purple [20]
  "169, 231, 1", // secondary green 1 [21]
  "0, 189, 79", // secondary green 3 [22]
  "255, 109, 4", // secondary orange 2 [23]
  "255, 31, 31", // secondary red 1 [24]
  "233, 33, 26", // secondary red 2 [25]
  "255, 204, 1", // yellow [26]
  "54, 177, 145", // secondary dark green [27]
  "249, 249, 251", //light grey [28]
  "154, 69, 185", //disabled medium [29]
  "126, 124, 255", // secondary purple 1 [30],
  "242, 242, 255", // light purple [31],
  "240, 245, 247", // light gray [32]
  "134, 140, 156", // medium gray [33]
  "241, 244, 247" // disabled [34]
] as const;

export const uniqueColors = uniq(COLORS);

export const STATUS_COLOR_CODES: Record<ApplicationStatus, number> = {
  0: 5,
  1: 7,
  2: 4,
  3: 1,
  4: 3,
  5: 0,
  6: 2,
  8: 0,
  9: 17
};

export const STAGE_TYPE_COLOR_CODES: Record<StageCategoryType, number> = {
  "Collect Data": 5,
  "Data Enrichment": 7,
  Placement: 4,
  Offer: 1,
  Closing: 3,
  Complete: 0,
  Scorecards: 17
};

export const DEAL_MODE_LIVE = "Live";
export const DEAL_MODE_TEST = "Test";

export const DEAL_MODE_LIVE_KEY = 1;
export const DEAL_MODE_TEST_KEY = 2;

export const DEAL_MODES = {
  [DEAL_MODE_LIVE_KEY]: DEAL_MODE_LIVE,
  [DEAL_MODE_TEST_KEY]: DEAL_MODE_TEST
} as const;

export const DEAL_MODE_COLOR_CODES = {
  [DEAL_MODE_LIVE]: 15,
  [DEAL_MODE_TEST]: 31
};

export const DEAL_MODE_TEXT_COLOR_CODES = {
  [DEAL_MODE_LIVE]: 33,
  [DEAL_MODE_TEST]: 30
};

export const DEALS_CLIENT_OPTIONS_UNCATEGORIZED = "uncategorized" as const;

export const SMARTVIEW_ALL_DEALS_NAME = "All";

export const DATA_ORCHESTRATION_MASTER_STATUS_NOT_STARTED = "Not Started";
export const DATA_ORCHESTRATION_MASTER_STATUS_PROCESSING = "Processing";
export const DATA_ORCHESTRATION_MASTER_STATUS_REVIEW = "Review";
export const DATA_ORCHESTRATION_MASTER_STATUS_COMPLETE_PASS = "Complete-Pass";
export const DATA_ORCHESTRATION_MASTER_STATUS_COMPLETE_FAIL = "Complete-Fail";
export const DATA_ORCHESTRATION_MASTER_STATUS_ERROR = "Error";

export const DATA_ORCHESTRATION_ACTION_RESUME_PASS = "pass-resume" as const;
export const DATA_ORCHESTRATION_ACTION_RESUME_FAIL = "fail-resume" as const;
export const DATA_ORCHESTRATION_ACTION_RETRY = "retry-step" as const;
export const DATA_ORCHESTRATION_ACTION_MASTER_STATUS = "master-status" as const;

export const DATA_ORCHESTRATION_ACTIONS = [
  DATA_ORCHESTRATION_ACTION_RESUME_PASS,
  DATA_ORCHESTRATION_ACTION_RESUME_FAIL,
  DATA_ORCHESTRATION_ACTION_RETRY,
  DATA_ORCHESTRATION_ACTION_MASTER_STATUS
] as const;

export const SENTILINK_MATCH_CONFIDENCES = {
  "1": "Very low",
  "2": "Low",
  "3": "Medium",
  "4": "High",
  "5": "Very high"
} as const;

export const ADD = "add";
export const EDIT = "edit";

export const DATA_ORCHESTRATION_MASTER_STATUSES: Record<
  string,
  DataOrchestrationMasterStatusType
> = {
  NOT_STARTED: 1,
  PROCESSING: 2,
  REVIEW: 3,
  COMPLETE_PASS: 4,
  COMPLETE_FAIL: 5,
  ERROR: 6
};
export const DATA_ORCHESTRATION_MASTER_STATUS_OPTIONS = {
  [DATA_ORCHESTRATION_MASTER_STATUS_NOT_STARTED]: {
    color: 15,
    icon: null,
    transitions: []
  },
  [DATA_ORCHESTRATION_MASTER_STATUS_PROCESSING]: {
    color: 5,
    icon: "processing",
    transitions: []
  },
  [DATA_ORCHESTRATION_MASTER_STATUS_REVIEW]: {
    color: 5,
    icon: "warning",
    transitions: [
      DATA_ORCHESTRATION_MASTER_STATUS_COMPLETE_PASS,
      DATA_ORCHESTRATION_MASTER_STATUS_COMPLETE_FAIL
    ]
  },
  [DATA_ORCHESTRATION_MASTER_STATUS_COMPLETE_PASS]: {
    color: 0,
    icon: "success-round-big",
    class: "text-green-400",
    transitions: [
      DATA_ORCHESTRATION_MASTER_STATUS_REVIEW,
      DATA_ORCHESTRATION_MASTER_STATUS_COMPLETE_FAIL
    ]
  },
  [DATA_ORCHESTRATION_MASTER_STATUS_COMPLETE_FAIL]: {
    color: 2,
    icon: "failure",
    class: "text-red-400",
    transitions: [
      DATA_ORCHESTRATION_MASTER_STATUS_REVIEW,
      DATA_ORCHESTRATION_MASTER_STATUS_COMPLETE_PASS
    ]
  },
  [DATA_ORCHESTRATION_MASTER_STATUS_ERROR]: {
    color: 2,
    icon: "failure",
    class: "text-red-400",
    transitions: [
      DATA_ORCHESTRATION_MASTER_STATUS_REVIEW,
      DATA_ORCHESTRATION_MASTER_STATUS_COMPLETE_PASS,
      DATA_ORCHESTRATION_MASTER_STATUS_COMPLETE_FAIL
    ]
  }
};

export const SERVICES_COLOR_CODES = {
  Bankruptcies: 16,
  "Experian Bankruptcies": 16,
  "Business Credit": 1,
  CFA: 0,
  Fraud: 18,
  KYB: 5,
  KYC: 17,
  Judgments: 7,
  UCCS: 14,
  Liens: 2,
  "Social Credit Score": 10,
  "Application Data": 15
} as const;

export const UTIL_COLORS = {
  ERROR: "#FF595E",
  DEFAULT: "#C2C6CD",
  DISABLED: "#545464",
  LABEL: "#868D9C",
  PRIMARY: "#3769D6",
  PURPLE: "#CB6EF6",
  SUCCESS: "#06D6A0",
  WARNING: "#FF9F04",
  WHITE: "#FFFFFF"
};

export const CODAT_CHART_COLORS: Record<string, string> = {
  Income: UTIL_COLORS.SUCCESS,
  Assets: UTIL_COLORS.SUCCESS,
  Liabilities: UTIL_COLORS.PRIMARY,
  "Cost of Sales": UTIL_COLORS.PRIMARY,
  grossProfit: UTIL_COLORS.PURPLE,
  Equity: UTIL_COLORS.PURPLE,
  Expenses: UTIL_COLORS.ERROR,
  netAssets: UTIL_COLORS.ERROR,
  netOperatingProfit: UTIL_COLORS.WARNING
};

export const PROFIT_AND_LOSS_FIXED_TEXT: Partial<Record<keyof Report, string>> =
  {
    grossProfit: "Gross Profit",
    netOperatingProfit: "Net Operating Profit",
    netOtherIncome: "Net Other Income",
    netProfit: "Net Profit",
    netAssets: "Net Assets"
  };

export const TRUNCATED_MONTHS = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec"
];

export const DEFAULT_TEMPLATE_ID = -1;

export const DEFAULT_TEMPLATE = {
  id: DEFAULT_TEMPLATE_ID,
  name: "Classic Lendflow",
  colour: "#272A40",
  button_colour: "#4476E5",
  button_text_colour: "#FFFFFF",
  heading_font_colour: "#FFFFFF",
  description_font_colour: "#7587A0",
  label_font_colour: "#545464",
  icon_colour: "#FFFFFF",
  progress_bar_colour: "#04FE72",
  logo: {
    file_url: ""
  },
  is_default: false,
  currency: "USD",
  locale: "en-US",
  widget_footer:
    '<p>By selecting "Next" you agree to our <a href="https://docs.google.com/document/u/1/d/e/2PACX-1vRM57l8G7IwCsFSD-E4Cp_AIv_BNPbWPqyxJ6rWHlA5Zhy50A4W0dambrC-zxzEsNOzzcoZUHZcPuDm/pub" target="_blank">Terms of Service</a> and <a href="https://docs.google.com/document/u/1/d/e/2PACX-1vTYHkkRmGmwBlIh-_9VfmCLHSMDNmIYJOfqDQ8o_5zgofB4Y6on7MM6XfftmLDC2g/pub" target="_blank">Privacy Policy</a>.</p>',
  widget_require_terms_acceptance: false
};

export const CFAlists = {
  estimated_revenue_txns: "Estimated Revenue Transaction",
  non_estimated_revenue_txns: "Deposit Not Included In Estimated Revenue",
  alternative_lender_txns: "List of Alternative Lender Transactions",
  factor_txns: "Factoring Transactions",
  merchant_cash_advance_txns: "Merchant Cash Advance Transactions",
  interbank_txns: "List of Interbank Transactions",
  nsf_transactions: "List of NSF Transactions",
  max_deposit_by_month: "Max Deposits By Month",
  min_deposit_by_month: "Min Deposits By Month",
  max_withdrawals_by_month: "Max Withdrawals By Month",
  nsf_transactions_returned_or_not_paid:
    "NSF Transactions Returned Or Not Paid",
  nsf_transactions_paid_or_negative_balance:
    "NSF Paid Or Negative Balance Count by Month",
  ppp_loan_txns: "PPP Loan Transactions",
  debt_consolidator_txns: "Debt Consolidator Transactions",
  probable_recurring_txns: "Probable Recurring Transactions",
  debit_transfer: "Debit Transfer",
  credit_transfer: "Credit Transfer",
  withdrawals: "Withdrawals",
  payroll: "Payroll",
  estimated_expense_txns: "Estimated Expense Transactions",
  insurance_debits: "Insurance Debits",
  insurance_credits: "Insurance Credits",
  round_number_txns: "Round Number Transactions"
};

export const dailyStats = {
  running_daily_balance: {
    title: "Running Daily Balance",
    type: "cfa-dynamic-table"
  },
  running_daily_cash_flow: {
    title: "Running Daily Cash Flow",
    type: "cfa-dynamic-table"
  },
  negative_cash_flows: { title: "Negative Cash Flows", type: "cfa-list" },
  negative_cash_flows_weekday: {
    title: "Weekday Negative Cash Flow",
    type: "cfa-list"
  }
};

export const monthlyStats = {
  total_deposits_count_by_month: {
    title: "Total Deposits Count By Month",
    type: "cfa-dynamic-table"
  },
  estimated_revenue_by_month: {
    title: "Estimated Revenue By Month",
    type: "cfa-dynamic-table"
  },
  estimated_daily_revenue_by_month: {
    title: "Estimated Daily Revenue By Month",
    type: "cfa-dynamic-table"
  },
  average_daily_balance_by_month: {
    title: "Average Daily Balance By Month",
    type: "cfa-dynamic-table"
  },
  average_daily_balance_weekdays_by_month: {
    title: "Average Daily Balance (Weekdays) by Month",
    type: "cfa-dynamic-table"
  },
  list_of_days_negative_balance_by_month: {
    title: "List of Days Negative Balance by Month",
    type: "cfa-list"
  },
  list_of_weekdays_negative_balance_by_month: {
    title: "List of Weekdays Negative Balance by Month",
    type: "cfa-list"
  },
  nsf_count_by_month: {
    title: "NSF Count By Month",
    type: "cfa-dynamic-table"
  },
  nsf_transactions_returned_or_not_paid_count_by_month: {
    title: "NSF Transactions Returned or Not Paid Count By Month",
    type: "cfa-dynamic-table"
  },
  nsf_transactions_paid_or_negative_balance_count_by_month: {
    title: "Nsf Transactions Paid or Negative Balance Count by Month",
    type: "cfa-dynamic-table"
  },
  merchant_cash_advance_payments_sum_by_month: {
    title: "Merchant Cash Advance Payments Sum By Month",
    type: "cfa-dynamic-table"
  },
  average_deposit_by_month: {
    title: "Average Deposit by Month",
    type: "cfa-dynamic-table"
  },
  average_transaction_size_by_month: {
    title: "Average Transaction Size By Month",
    type: "cfa-dynamic-table"
  },
  total_withdrawals_sum_by_month: {
    title: "Total Withdrawals Sum by Month",
    type: "cfa-dynamic-table"
  },
  net_cash_flows_by_month: {
    title: "Net Cash Flows By Month",
    type: "cfa-dynamic-table"
  },
  recurring_vs_episodic_inflows_by_month: {
    title: "Recurring vs Episodic Inflows by Month",
    type: "cfa-dynamic-table"
  },
  recurring_vs_episodic_outflows_by_month: {
    title: "Recurring vs Episodic Outflows by Month",
    type: "cfa-dynamic-table"
  },
  total_alternative_lender_payments_sum_by_month: {
    title: "Total Alternative Lender Payments Sum by Month",
    type: "cfa-dynamic-table"
  },
  cash_expense_days_by_month: {
    title: "Cash Expense Days by Month",
    type: "cfa-dynamic-table"
  }
};

export const WORKFLOWS = {
  is_business_credit: "Credit Review",
  is_funding: "Marketplace",
  is_equipment_rental: "Rental",
  is_data_capture: "Data Collection",
  is_client_funded: "Internal Funding"
};

export const PHASE_IDS_FOR_CLIENT_UNDERWRITER_AND_ER_APPS = [
  "0", // Application
  "1", // Underwriting
  "7", // Funding,
  "8" //"Complete"
];

export const PHASE_APPLICATION = "Application";
export const PHASE_UNDERWRITING = "Underwriting";
export const PHASE_SCORECARDS = "ScoreCards";
export const PHASE_PLACEMENT = "Placement";
export const PHASE_OFFER = "Offer";
export const PHASE_CLOSING = "Closing";
export const PHASE_FUNDED = "Funded";
export const PHASE_COMPLETE = "Complete";
export const PHASE_ABSTRACT_APPROVED = "Approved";

export const STATUS_DESCRIPTIONS = {
  0: "Application",
  1: "Underwriting",
  2: "Placement",
  3: "Offer",
  4: "Closing",
  5: "Funded",
  6: "Dead",
  8: "Approved",
  9: "ScoreCards"
};

export const PHASES_FOR_LENDFLOW_FOR_FUNDING_APPS = [
  PHASE_APPLICATION,
  PHASE_UNDERWRITING,
  PHASE_SCORECARDS,
  PHASE_PLACEMENT,
  PHASE_OFFER,
  PHASE_CLOSING,
  PHASE_FUNDED
];

export const ENTRIES = {
  BANKRUPTCIES: "bankruptcies",
  ADDRESS_VERIFICATION: "address_verification",
  ADDRESS_DELIVERABILITY: "address_deliverability",
  ADDRESS_PROPERTY_TYPE: "address_property_type",
  PERSON_VERIFICATION: "person_verification",
  BUSINESS_NAME: "name",
  TIN: "tin",
  SOS_MATCH: "sos_match",
  SOS_ACTIVE: "sos_active",
  SOS_INACTIVE: "sos_inactive",
  SOS_DOMESTIC: "sos_domestic",
  SOS_NOT_FOUND: "sos_not_found",
  SOS_UNKNOWN: "sos_unknown",
  PHONE: "phone",
  WEBSITE_STATUS: "website_status",
  WATCHLIST: "watchlist",
  INDUSTRY: "industry"
};

export const ENTRIES_ORDER = [
  ENTRIES.BUSINESS_NAME,
  ENTRIES.ADDRESS_VERIFICATION,
  ENTRIES.PERSON_VERIFICATION,
  ENTRIES.TIN,
  ENTRIES.PHONE
];

export const EXCLUDED_ENTRY_TYPES = [
  ENTRIES.BANKRUPTCIES,
  ENTRIES.ADDRESS_DELIVERABILITY,
  ENTRIES.ADDRESS_PROPERTY_TYPE,
  ENTRIES.SOS_MATCH,
  ENTRIES.SOS_ACTIVE,
  ENTRIES.SOS_INACTIVE,
  ENTRIES.SOS_DOMESTIC,
  ENTRIES.SOS_NOT_FOUND,
  ENTRIES.SOS_UNKNOWN,
  ENTRIES.WEBSITE_STATUS,
  ENTRIES.WATCHLIST,
  ENTRIES.INDUSTRY
];

export const OFFER_SECTION = "offer";
export const FUNDING_SECTION = "funding";

export const WORKFLOW_FUNDING = "funding";
export const WORKFLOW_BUSINESS_CREDIT = "business_credit";
export const WORKFLOW_EQUIPMENT_RENTAL = "equipment_rental";
export const WORKFLOW_DATA_CAPTURE = "data_capture";
export const WORKFLOW_INTERNAL_FUNDING = "internal_funding";

export const SF_WORKFLOWS = {
  [WORKFLOW_FUNDING]: "Marketplace",
  [WORKFLOW_BUSINESS_CREDIT]: "Credit Review",
  [WORKFLOW_EQUIPMENT_RENTAL]: "Equipment Rental",
  [WORKFLOW_DATA_CAPTURE]: "Data Collection",
  [WORKFLOW_INTERNAL_FUNDING]: "Internal Funding"
} as Record<Workflow, string>;

export const WORKFLOW_TO_APPLICATION_CATEGORIES = {
  1: WORKFLOW_FUNDING,
  2: WORKFLOW_BUSINESS_CREDIT,
  3: WORKFLOW_EQUIPMENT_RENTAL,
  4: WORKFLOW_INTERNAL_FUNDING,
  5: WORKFLOW_DATA_CAPTURE
} as const;

export const WORKFLOW_MAPPINGS = {
  is_business_credit: WORKFLOW_BUSINESS_CREDIT,
  is_funding: WORKFLOW_FUNDING,
  is_equipment_rental: WORKFLOW_EQUIPMENT_RENTAL,
  is_data_capture: WORKFLOW_DATA_CAPTURE,
  is_client_funded: WORKFLOW_INTERNAL_FUNDING
} as Record<string, Workflow>;

export const RISK_CLASS = {
  0: { text: "-", color: "default" },
  1: { text: "LOW", color: "success" },
  2: { text: "LOW TO MEDIUM", color: "success" },
  3: { text: "MEDIUM", color: "warning" },
  4: { text: "MEDIUM TO HIGH", color: "warning" },
  5: { text: "HIGH", color: "error" }
};

export const CLASS_RATING = {
  0: { text: "-", color: "default" },
  1: { text: "HIGH RISK", color: "error" },
  2: { text: "MEDIUM TO HIGH RISK", color: "warning" },
  3: { text: "MEDIUM RISK", color: "warning" },
  4: { text: "LOW RISK", color: "success" },
  5: { text: "VERY LOW RISK", color: "success" }
} as const;

export const OFFER_STATUS_DECLINED = 3;
export const OFFER_STATUS_SENT = 2;

export const SUBMISSION_TYPES = {
  1: "API",
  2: "Email",
  3: "Manual",
  4: "Lendflow"
} as const;

export const NOTE_VISIBILITY_SCOPE = {
  ALL: 1,
  LENDFLOW: 2,
  CLIENT: 3
};

export const NOTE_VISIBILITY_SCOPES = {
  1: { text: "Everyone", icon: "people" },
  3: { text: "Only Me", icon: "user" },
  99: { text: "Other Groups", icon: "star" }
};

export const OTHER_GROUPS_VISIBILITY = {
  CLIENT: {
    6: "My Team",
    4: "External Funders"
  },
  FUNDER: {
    7: "My Team",
    5: "Organization"
  },
  LENDFLOW: {
    2: "Lendflow",
    5: "Organization",
    4: "External Funders"
  }
};

export const EXTERNAL_FUNDERS_KEY = 4;

export const ALL_NOTE_VISIBILITY_TYPES = {
  1: "Everyone",
  3: "Only Me",
  ...OTHER_GROUPS_VISIBILITY.CLIENT,
  ...OTHER_GROUPS_VISIBILITY.FUNDER,
  ...OTHER_GROUPS_VISIBILITY.LENDFLOW
};

export const NOTES_TYPES: Record<string, NoteType> = {
  APPLICATION_NOTE: NoteType.Application,
  BUSINESS_NOTE: NoteType.Business,
  CALL_NOTE: NoteType.Call
};

export const FUNDED_POSITIONS = {
  ["1st"]: "1st",
  ["2nd"]: "2nd",
  ["3rd"]: "3rd",
  ["4th"]: "4th"
};

export const NO_FIELDS_STAGES = ["underwriting", "closing", "funded"];

export const ICON_FOR_CONDITION: Record<DataOrchestrationOutcome, string> = {
  pass: "success-big-green",
  fail: "fail-big-red",
  "manual review": "warning",
  undetermined: ""
};

export const CONFIDENCE_LEVEL_COLORS_AND_COPY = {
  very_high: { text: "Very High", colorCode: 22, hexCode: "#00BD4F" },
  high: { text: "High", colorCode: 0, hexCode: "#06D6A0" },
  medium: { text: "Medium", colorCode: 5, hexCode: "#FF9F04" },
  low: { text: "Low", colorCode: 23, hexCode: "#FF6D04" },
  very_low: { text: "Very Low", colorCode: 2, hexCode: "#FF595E" }
};

export const FULL_MONTHS: Record<string, string> = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December"
} as const;
export const PERIOD_MONTHS: Record<string, string> = {
  1: "1 month",
  2: "2 months",
  3: "3 months",
  4: "4 months",
  5: "5 months",
  6: "6 months",
  7: "7 months",
  8: "8 months",
  9: "9 months",
  10: "10 months",
  11: "11 months",
  12: "12 months"
} as const;

export const MONTHS = [
  { monthNum: 1, name: "jan" },
  { monthNum: 2, name: "feb" },
  { monthNum: 3, name: "mar" },
  { monthNum: 4, name: "apr" },
  { monthNum: 5, name: "may" },
  { monthNum: 6, name: "jun" },
  { monthNum: 7, name: "jul" },
  { monthNum: 8, name: "aug" },
  { monthNum: 9, name: "sep" },
  { monthNum: 10, name: "oct" },
  { monthNum: 11, name: "nov" },
  { monthNum: 12, name: "dec" }
];

export const DO_SCHEMA_VERSION = "1.19.0" as const;
export const DO_TYPE_DATA_SERVICE = "data-service" as const;
export const DO_TYPE_CONDITIONAL = "conditional" as const;
export const DO_MANUAL_REVIEW = "manual review" as const;

export const DO_SAVE_TEMPLATE_MODES = {
  publish: 2,
  draft: 1
} as const;

export const DO_TEMPLATE_REFRESH_TTL_PERIODS = {
  hours: "Hours",
  days: "Days",
  months: "Months"
} as Record<DataOrchestrationTemplateRePullPeriod, string>;

export const DO_TEMPLATE_REFRESH_TTL_CONVERSIONS_FROM_HOURS = {
  hours: 1,
  days: 24,
  months: 720 // 24 * 30 = 720; Agreed to use 30 as a round number for conversion to months
} as Record<DataOrchestrationTemplateRePullPeriod, number>;

export const DISABLED_SERVICES_FOR_DATA_RE_PULL = ["Persona", "Inscribe"];

export const DO_DRAGGABLE_DEFAULT: IDoDraggable = {
  isDragging: false,
  type: ""
};

export const DEFAULT_DO_TRIGGER = "manual";

export const PANEL_TYPE_TRIGGER = "trigger" as const;
export const PANEL_TYPE_SERVICE = "service" as const;
export const PANEL_TYPE_OTHER = "other" as const;
export const PANEL_TYPE_OTHER_CUSTOM = "custom" as const;
export const PANEL_TYPE_CONDITIONAL = "conditional" as const;

export const PAUSED_ORCHESTRATION_STATUS = 5 as const;

export enum USER_COLORS {
  GRAY,
  GREEN,
  RED,
  BLUE,
  MINT,
  PINK,
  PURPLE
}

export const USER_BADGE_COLORS: Record<USER_COLORS, AvatarTheme> = {
  [USER_COLORS.GRAY]: { text: "#545464", background: "#f1f4f7" },
  [USER_COLORS.GREEN]: { text: "#545464", background: "#f1f4c8" },
  [USER_COLORS.RED]: { text: "#e9211a", background: "#ffeeef" },
  [USER_COLORS.BLUE]: { text: "#3769d6", background: "#edf4fe" },
  [USER_COLORS.MINT]: { text: "#545464", background: "#d0f7ed" },
  [USER_COLORS.PINK]: { text: "#e925d7", background: "#ffeef6" },
  [USER_COLORS.PURPLE]: { text: "#963add", background: "#f2e6fb" }
};

export const TEMPLATE_STATUSES = {
  0: "Draft",
  1: "Awaiting Approval",
  2: "Approved",
  3: "Declined"
};

export const EMAIL_TEMPLATE_STATUSES = {
  0: "Awaiting Approval",
  1: "Approved",
  2: "Declined"
};

export enum SCORECARDS_STATUSES {
  DRAFT = 1,
  FINISHED = 2
}

export enum DOC_TYPES {
  PDF = "application/pdf"
}

export const WORKFLOW_STATUS_COLOR_CODES = {
  0: 15,
  1: 5,
  2: 0,
  3: 2
};

export const EMAIL_TEMPLATE_STATUS_COLOR_CODES = {
  0: 5,
  1: 0,
  2: 2
};

export const WORKFLOW_ACTIVE_STATUSES = {
  0: "Inactive",
  1: "Active"
} as const;

export const WORKFLOW_APPROVED_STATUSES = {
  0: "Not approved",
  1: "Approved"
} as const;

export const WORKFLOW_GLOBAL_OPTIONS = {
  1: "Global Template",
  0: "Non-global Template"
} as const;

export const WORKFLOW_MANAGED_BY_OPTIONS = {
  1: "Lendflow",
  0: "Client"
} as const;

export const WORKFLOW_TYPE_LINEAR = "linear";
export const WORKFLOW_TYPE_DYNAMIC = "dynamic";

export const WORKFLOW_TYPES = {
  [WORKFLOW_TYPE_LINEAR]: "Linear",
  [WORKFLOW_TYPE_DYNAMIC]: "Dynamic"
};

export const WORKFLOW_TYPES_OPTIONS = [
  {
    name: WORKFLOW_TYPE_LINEAR,
    icon: "workflow-linear",
    i18n: "WORKFLOW.TYPES.LINEAR"
  }
  //TODO: uncomment once dynamic workflows become available
  // {
  //   name: WORKFLOW_TYPE_DYNAMIC,
  //   icon: "workflow-dynamic",
  //   i18n: "WORKFLOW.TYPES.DYNAMIC"
  // }
];

export const WORKFLOW_SEGMENT_BUSINESS = "business";

export const WORKFLOW_CATEGORY_MARKETPLACE: WorkflowCategory =
  "marketplace" as const;
export const WORKFLOW_CATEGORY_CREDIT_REVIEW: WorkflowCategory =
  "credit_review" as const;
export const WORKFLOW_CATEGORY_RENTAL: WorkflowCategory = "rental" as const;
export const WORKFLOW_CATEGORY_DATA_COLLECTION: WorkflowCategory =
  "data_collection" as const;
export const WORKFLOW_CATEGORY_INTERNAL_FUNDING: WorkflowCategory =
  "internal_funding" as const;

export const WORKFLOW_CATEGORIES_OPTIONS = {
  [WORKFLOW_CATEGORY_MARKETPLACE]: "Marketplace",
  [WORKFLOW_CATEGORY_CREDIT_REVIEW]: "Credit Review",
  [WORKFLOW_CATEGORY_RENTAL]: "Rental",
  [WORKFLOW_CATEGORY_DATA_COLLECTION]: "Data Collection",
  [WORKFLOW_CATEGORY_INTERNAL_FUNDING]: "Internal Funding"
} as Record<string, string>;

export const WORKFLOW_CATEGORIES = [
  {
    id: 1,
    name: "MARKETPLACE"
  },
  {
    id: 2,
    name: "CREDIT_REVIEW"
  },
  {
    id: 3,
    name: "RENTAL"
  },
  {
    id: 4,
    name: "INTERNAL_FUNDING"
  },
  {
    id: 5,
    name: "DATA_COLLECTION"
  }
] as const;

export const STATE_NAMES = {
  AL: "ALABAMA",
  AK: "ALASKA",
  AS: "AMERICAN SAMOA",
  AZ: "ARIZONA",
  AR: "ARKANSAS",
  CA: "CALIFORNIA",
  CO: "COLORADO",
  CT: "CONNECTICUT",
  DE: "DELAWARE",
  DC: "DC",
  FL: "FLORIDA",
  GA: "GEORGIA",
  GU: "GUAM",
  HI: "HAWAII",
  ID: "IDAHO",
  IL: "ILLINOIS",
  IN: "INDIANA",
  IA: "IOWA",
  KS: "KANSAS",
  KY: "KENTUCKY",
  LA: "LOUISIANA",
  ME: "MAINE",
  MD: "MARYLAND",
  MA: "MASSACHUSETTS",
  MI: "MICHIGAN",
  MN: "MINNESOTA",
  MS: "MISSISSIPPI",
  MO: "MISSOURI",
  MT: "MONTANA",
  NE: "NEBRASKA",
  NV: "NEVADA",
  NH: "NEW HAMPSHIRE",
  NJ: "NEW JERSEY",
  NM: "NEW MEXICO",
  NY: "NEW YORK",
  NC: "NORTH CAROLINA",
  ND: "NORTH DAKOTA",
  OH: "OHIO",
  OK: "OKLAHOMA",
  OR: "OREGON",
  PA: "PENNSYLVANIA",
  RI: "RHODE ISLAND",
  SC: "SOUTH CAROLINA",
  SD: "SOUTH DAKOTA",
  TN: "TENNESSEE",
  TX: "TEXAS",
  UT: "UTAH",
  VT: "VERMONT",
  VA: "VIRGINIA",
  WA: "WASHINGTON",
  WV: "WEST VIRGINIA",
  WI: "WISCONSIN",
  WY: "WYOMING"
} as const;

export const DO_CFA_BOOLEAN_ATTRIBUTES = [
  "ocrolus_cfa_unexpected_error",
  "plaid_connection",
  "ocrolus_has_bank_data"
];

export const TYPE_BOOLEAN: ConditionType = "boolean" as const;
export const TYPE_NULL: ConditionType = "is_null" as const;
export const TYPE_NOT_NULL: ConditionType = "is_not_null" as const;
export const TYPE_LESS_THAN: ConditionType = "less_than" as const;
export const TYPE_LESS_THAN_OR_EQUAL: ConditionType =
  "less_than_or_equal" as const;
export const TYPE_GREATER_THAN: ConditionType = "greater_than" as const;
export const TYPE_GREATER_THAN_OR_EQUAL: ConditionType =
  "greater_than_or_equal" as const;
export const TYPE_EQUAL: ConditionType = "equal" as const;
export const TYPE_NOT_EQUAL: ConditionType = "not_equal" as const;
export const TYPE_IN: ConditionType = "in" as const;
export const TYPE_NOT_IN: ConditionType = "not_in" as const;
export const TYPE_RANGE = "range" as ConditionType; // this is not a valid type on the backend
export const TYPE_NAICS_IN_RANGE: ConditionType = "naics_code_in_range";
export const TYPE_NAICS_NOT_IN_RANGE: ConditionType = "naics_code_not_in_range";

export const SCORECARD_TYPE_MANUAL: ScorecardType = 1 as const;
export const SCORECARD_TYPE_BUILDER: ScorecardType = 2 as const;
export const SCORECARD_STATUS_DRAFT = 1 as const;
export const SCORECARD_STATUS_FINISHED = 2 as const;
export const SCORECARD_STATUS_KNOCKOUT = 3 as const;
export const SCORECARD_VIEW_SCORECARDS_TAB = "scorecards";
export const SCORECARD_VIEW_SCORECARD_GROUPS_TAB = "scorecardGroups";
export const SCORECARD_SCHEMA_VERSION = "1.4.0";

export const SCORECARD_COLOR_SWATCHES = [
  "#FFCC00",
  "#3769D6",
  "#B033FE",
  "#FF9F04",
  "#FF1F1F",
  "#2C92FF",
  "#E925D7",
  "#7E7CFF",
  "#10B981",
  "#FCD34D",
  "#BFDBFE",
  "#C4B5FD",
  "#FCA5A5",
  "#F9A8D4",
  "#A5B4FC",
  "#6EE7B7"
] as const;

const RT_STATUSES = {
  SUPER_PRIME: "Super Prime",
  NEAR_PRIME: "Near Prime",
  PRIME: "Prime",
  SUBPRIME: "Subprime",
  DEEP_PRIME: "Deep Prime",
  DEAD: "Dead"
};

export const DEFAULT_RT_ITEMS = [
  {
    label: RT_STATUSES.SUPER_PRIME,
    indicator: 1
  },
  {
    label: RT_STATUSES.NEAR_PRIME,
    indicator: 2
  },
  {
    label: RT_STATUSES.PRIME,
    indicator: 3
  },
  {
    label: RT_STATUSES.SUBPRIME,
    indicator: 4
  },
  {
    label: RT_STATUSES.DEEP_PRIME,
    indicator: 5
  },
  {
    label: RT_STATUSES.DEAD,
    indicator: 6
  }
];

export const DNB_FI_SERVICES: IDnbLevel[] = [
  {
    key: "fi_l2",
    title: "Financial Strength Insights (Level 2)",
    abbreviation: "L2"
  },
  {
    key: "fi_l3",
    title: "Financial Strength Insights (Level 3)",
    abbreviation: "L3"
  },
  {
    key: "fi_l4",
    title: "Financial Strength Insights (Level 4)",
    abbreviation: "L4"
  }
];
export const EXPERIAN_V_SERVICES: IExperianLevel[] = [
  {
    key: "intelliscore",
    title: "Experian Intelliscore (Version 2)",
    abbreviation: "V2"
  },
  {
    key: "intelliscore_v3",
    title: "Experian Intelliscore (Version 3)",
    abbreviation: "V3"
  }
];
export const EXPERIAN_FSR_SERVICES: IExperianLevel[] = [
  {
    key: "fsr",
    title: "Experian Financial Stability Risk Score (Version 1)",
    abbreviation: "V1"
  },
  {
    key: "fsr_v2",
    title: "Experian Financial Stability Risk Score (Version 2)",
    abbreviation: "V2"
  }
];
export const DNB_PI_SERVICES = (): IDnbLevel[] => [
  {
    key: "pi_l3",
    title: i18n.global.t("SIDEBAR.DNB.PAYMENT_INSIGHT_LEVEL"),
    abbreviation: "L3"
  }
];

export const DNB_RISK_LEVEL_COLOR_CODES: Record<string, number> = {
  Low: 4,
  "Low-Moderate": 21,
  Moderate: 16,
  "Moderate-High": 5,
  High: 2
};
export const CURRENCY_OPTIONS_NO_DECIMALS = {
  currency: "USD",
  distractionFree: false,
  useGrouping: true,
  precision: 0,
  valueRange: { min: 0, max: 999999999 }
};

export const SOCURE_KYC_FIELDS: (keyof ISocureFields)[] = [
  "firstName",
  "surName",
  "streetAddress",
  "city",
  "state",
  "zip",
  "mobileNumber",
  "ssn",
  "dob"
];

export const SOCURE_FRAUD_SCORES_FIELDS: (keyof ISocureFraud)[] = [
  "synthetic",
  "emailRisk",
  "addressRisk",
  "fraud",
  "phoneRisk",
  "nameAddressCorrelation",
  "nameEmailCorrelation",
  "namePhoneCorrelation"
];

export const USER_BLUEPRINT: Partial<IUser> = {
  assigned_clients: [],
  assigned_worklows: [],
  calendar_link: "",
  email: "",
  first_name: "",
  last_name: "",
  pending_email: "",
  preferences: [],
  roles: []
};

export const COMMON_GET_QUERY = {
  page: 1,
  per_page: 100
} as const;

export const VIEW_TYPE_LIST = "list" as const;

export const VIEW_TYPE_TILE = "tile" as const;

export const EMAILS_EXCLUDED_FROM_OKTA_ENFORCEMENT = [
  "devops+automation@lendflow.com"
];

export const PROVIDER_EXPERIAN_BANKRUPTCIES = "experian_bankruptcies" as const;
export const PROVIDER_MIDDESK = "middesk" as const;
export const PROVIDER_ENIGMA = "enigma" as const;
export const PROVIDER_ENIGMA_LOOKUP = "enigma_lookup" as const;
export const PROVIDER_EQUIFAX = "equifax" as const;
export const PROVIDER_DNB_FI_L4 = "dnb_fi_l4" as const;
export const PROVIDER_DNB_BM_L1 = "dnb_bm_l1" as const;
export const PROVIDER_DNB_CI_L2 = "dnb_ci_l2" as const;
export const PROVIDER_EXPERIAN_BUSINESS_CONTACTS =
  "experian_business_contacts" as const;
export const PROVIDER_EXPERIAN_CORPORATE_REGISTRATIONS =
  "experian_corporate_registrations" as const;
export const PROVIDER_EXPERIAN_LEGAL_COLLECTIONS =
  "experian_legal_collections" as const;
export const PROVIDER_EXPERIAN_BUSINESS_MATCH =
  "experian_business_match" as const;
export const PROVIDER_EXPERIAN_BUSINESS_MATCH_CANADA =
  "experian_business_match_canada" as const;
export const PROVIDER_EXPERIAN_BUSINESS_MATCH_QUICK_SEARCH =
  "experian_business_quick_search" as const;
export const PROVIDER_EXPERIAN_FSR = "experian_fsr" as const;
export const PROVIDER_EXPERIAN_FSR_V2 = "experian_fsr_v2" as const;
export const PROVIDER_EXPERIAN_INTELLISCORE = "experian_intelliscore" as const;
export const PROVIDER_EXPERIAN_INTELLISCORE_V2 = "intelliscore" as const;
export const PROVIDER_EXPERIAN_INTELLISCORE_V3 = "intelliscore_v3" as const;
export const PROVIDER_EXPERIAN_INTELLISCORE_V3_LONG =
  "experian_intelliscore_v3" as const;
export const PROVIDER_EXPERIAN_TRADES = "experian_trades" as const;
export const PROVIDER_EXPERIAN_FACTS = "experian_business_facts" as const;
export const PROVIDER_EXPERIAN_JUDGMENTS = "experian_judgments" as const;
export const PROVIDER_EXPERIAN_LIENS = "experian_liens" as const;
export const PROVIDER_EXPERIAN = "experian" as const;
export const PROVIDER_EXPERIAN_UCCS = "experian_uccs" as const;
export const PROVIDER_EXPERIAN_GDN_CANADIAN_PROFILE_REPORT =
  "experian_gdn_canadian_profile_report" as const;
export const PROVIDER_EXPERIAN_GDN_COMPANY_PROFILE =
  "experian_gdn_company_profile" as const;
export const PROVIDER_EXPERIAN_GDN_EXTENDED_REPORT =
  "experian_gdn_extended_report" as const;
export const PROVIDER_EXPERIAN_GDN_RISK_CHECK =
  "experian_gdn_risk_check" as const;
export const PROVIDER_EXPERIAN_GDN_SMALL_REPORT =
  "experian_gdn_small_report" as const;
export const PROVIDER_EXPERIAN_COMMERCIAL_COLLECTIONS =
  "experian_commercial_collections" as const;
export const PROVIDER_EXPERIAN_CREDIT_STATUS =
  "experian_credit_status" as const;
export const PROVIDER_SCORELY = "scorely" as const;
export const PROVIDER_OCROLUS = "ocrolus" as const;
export const PROVIDER_OCROLUS_CFA = "ocrolus_cfa" as const;
export const PROVIDER_OCROLUS_CFF = "ocrolus_cff" as const;
export const PROVIDER_OCROLUS_ET = "ocrolus_enriched_transactions" as const;
export const PROVIDER_OCROLUS_CFA_V2 = "ocrolus_cfa_v2" as const;
export const PROVIDER_OCROLUS_FRAUD_SIGNALS = "ocrolus_fraud_signals" as const;
export const PROVIDER_HERON = "heron" as const;
export const PROVIDER_SENTILINK = "sentilink" as const;
export const PROVIDER_SENTILINK_SSN_COMPLETION =
  "sentilink_ssn_completion" as const;
export const PROVIDER_SENTILINK_DOB_COMPLETION =
  "sentilink_dob_completion" as const;
export const PROVIDER_EKATA = "ekata" as const;
export const PROVIDER_PLAID = "plaid" as const;
export const PROVIDER_PLAID_ASSET_REPORT = "plaid_asset_report" as const;
export const PROVIDER_PLAID_BANK_DATA = "plaid_bank_data" as const;
export const PROVIDER_INSCRIBE = "inscribe" as const;
export const PROVIDER_PERSONA = "persona" as const;
export const PROVIDER_CLEAR = "clear" as const;
export const SEARCH = "search" as const;
export const REPORT = "report" as const;
export const PROVIDER_LEXIS_NEXIS = "lexisNexis" as const;
export const PROVIDER_LEXIS_NEXIS_KYC = "lexis_nexis_kyc" as const;
export const PROVIDER_LEXIS_NEXIS_KYC_REPORT =
  "lexis_nexis_kyc_report" as const;
export const PROVIDER_LEXIS_NEXIS_BANKRUPTCY_SEARCH =
  "lexis_nexis_bankruptcy_search" as const;
export const PROVIDER_LEXIS_NEXIS_BANKRUPTCY_REPORT =
  "lexis_nexis_bankruptcy_report" as const;
export const PROVIDER_LEXIS_NEXIS_JUDGMENTS_SEARCH =
  "lexis_nexis_liens_and_judgment_search" as const;
export const PROVIDER_LEXIS_NEXIS_JUDGMENTS_REPORT =
  "lexis_nexis_judgments_report" as const;
export const PROVIDER_CLEAR_ID_CONFIRM_BUSINESS =
  "clear_id_confirm_business" as const;
export const PROVIDER_CLEAR_ID_BUSINESS_REPORT =
  "clear_risk_inform_business_report" as const;
export const PROVIDER_CLEAR_ID_BUSINESS_SEARCH =
  "clear_risk_inform_business_search" as const;
export const PROVIDER_CLEAR_ID_BUSINESS = "clear_risk_inform_business" as const;
export const PROVIDER_CLEAR_ID_BUSINESS_KYB =
  "clear_risk_inform_business_kyb" as const;
export const PROVIDER_CLEAR_PERSON_SEARCH =
  "clear_risk_inform_person_search" as const;
export const PROVIDER_CLEAR_PERSON_REPORT =
  "clear_risk_inform_person_report" as const;
export const PROVIDER_CLEAR_ID_PERSON_REPORT =
  "clear_risk_inform_person_report" as const;
export const PROVIDER_EQUIFAX_BUSINESS_PRINCIPAL_REPORT =
  "equifax_business_principal_report" as const;
export const PROVIDER_CLEAR_COURT_SEARCH = "clear_court_search" as const;
export const PROVIDER_CLEAR_COURT_SEARCH_LIENS =
  "clear_court_search_liens" as const;
export const PROVIDER_CLEAR_ID_PERSON_SEARCH =
  "clear_risk_inform_person_search" as const;
export const PROVIDER_CLEAR_ID_PERSON_SEARCH_LIENS =
  "clear_risk_inform_person_search_liens" as const;
export const PROVIDER_CLEAR_ID_PERSON = "clear_risk_inform_person" as const;
export const PROVIDER_CLEAR_ID_PERSON_UCC =
  "clear_risk_inform_person_ucc" as const;
export const PROVIDER_CLEAR_ID_CONFIRM_PERSON =
  "clear_id_confirm_person" as const;
export const PROVIDER_CLEAR_ADVERSE_MEDIA_SEARCH =
  "clear_adverse_media_search" as const;
export const PROVIDER_CLEAR_ADVERSE_MEDIA_REPORT =
  "clear_adverse_media_report" as const;
export const PROVIDER_CLEAR_ID_CONFIRM_PERSON_MATCH =
  "clear_id_confirm_person_match" as const;
export const PROVIDER_LEXISNEXIS_KYB_SEARCH = "lexis_nexis_kyb_search" as const;
export const PROVIDER_LEXISNEXIS_KYB_REPORT = "lexis_nexis_kyb_report" as const;
export const PROVIDER_LEXISNEXIS_LIENS_SEARCH =
  "lexis_nexis_liens_and_judgment_search" as const;
export const PROVIDER_LEXISNEXIS_JUDGMENTS_REPORT =
  "lexis_nexis_judgments_report" as const;
export const PROVIDER_LEXISNEXIS_LIENS_REPORT =
  "lexis_nexis_liens_report" as const;
export const PROVIDER_LEXISNEXIS_BUSINESS_CREDIT =
  "lexis_nexis_small_business_credit_score" as const;
export const PROVIDER_LEXISNEXIS_CONTACT_CARD_REPORT =
  "lexis_nexis_contact_card_report" as const;
export const PROVIDER_LEXISNEXIS_EMAIL_SEARCH =
  "lexis_nexis_email_search" as const;
export const PROVIDER_RAILZ = "railz" as const;
export const PROVIDER_CODAT = "codat" as const;
export const PROVIDER_LEXIS_NEXIS_FILING_SEARCH =
  "lexis_nexis_ucc_filing_search" as const;
export const PROVIDER_LEXIS_NEXIS_FILING_REPORT =
  "lexis_nexis_ucc_filing_report" as const;
export const PROVIDER_MONEYTHUMB_CFA = "moneythumb_cfa" as const;
export const PROVIDER_MONEYTHUMB_TRANSACTIONS =
  "moneythumb_transactions" as const;
export const PROVIDER_MONEYTHUMB = "MoneyThumb" as const;
export const PROVIDER_BLU_COGNITION = "blu_cognition" as const;
export const PROVIDER_EXPERIAN_FRAUD = "experian_fraud" as const;
export const PROVIDER_TRANSUNION = "transunion" as const;
export const PROVIDER_TRANSUNION_PERSONAL_CREDIT =
  "transunion_credit_report" as const;
export const PROVIDER_DOCUSIGN = "docusign" as const;

export const BORROWER_TYPES = {
  1: "Business",
  2: "Personal"
} as const;

export const LENDING_WIDGET_ID = "1" as const;
export const DOCUSIGN_WIDGET_ID = "2" as const;
export const PLAID_BANK_LINK_WIDGET_ID = "3" as const;
export const DOCUSIGN_PLAID_WIDGET_ID = "5" as const;
export const LENDING_WIDGET_NO_PREQUAL_ID = "9" as const;
export const LENDING_WIDGET_INTERNATIONAL_ID = "10" as const;
export const INVOICE_FACTORING_WIDGET_ID = "24" as const;
export const INVOICE_FACTORING_RAILZ_WIDGET_ID = "14" as const;
export const INVOICE_FACTORING_CODAT_WIDGET_ID = "22" as const;
export const LENDING_WIDGET_NO_PREQUAL_DOCU_PLAID_ID = "18" as const;
export const LENDING_WIDGET_NO_PREQUAL_DOCU_ID = "19" as const;
export const ERC_WIDGET = "23" as const;
export const ERC_WIDGET_LARGE = "29" as const;
export const END_USER_CREDIT_SCORE_CLIENT_ID = "4" as const;
export const END_USER_CREDIT_SCORE_APPLICANT_ID = "8" as const;
export const END_USER_CREDIT_SCORE_MULTIPLE_OWNER_ID = "26" as const;
export const EQUIPMENT_RENTAL_ID = "7" as const;
export const ISO_APPLICATION = "25" as const;
export const LENDING_WIDGET_ID_LARGE_DEALS = "27" as const;
export const CODAT_WIDGET_ID = "21" as const;
export const MCA_NO_PREQUAL_OFFER_ID = "30" as const;
export const FACTORING_WIDGET_ID = "32" as const;

export const TYPE_UPLOAD = "upload" as const;
export const TYPE_DISPLAY = "display" as const;
export const TYPE_DISPLAY_PDF_ONLY = "display_pdf_only" as const;
export const TYPE_PROCESSING = "processing" as const;
export const INSCRIBE_PROCESSED = "PROCESSED" as const;

export const EXPERIAN_BUSINESS_OWNER_PROFILE =
  "Experian Business Owner Profile" as const;
export const EXPERIAN_BUSINESS_OWNER_PROFILE_PREMIER =
  "Experian Business Owner Profile Premier Attributes" as const;

export const BACKGROUND_TYPE = {
  COLOR: "color",
  IMAGE: "image"
} as const;

export const GREATER_TYPES_SSN_CONFIDENCE_LEVELS = [
  "greater_than",
  "greater_than_or_equal"
] as const;
export const LESSER_TYPES_SSN_CONFIDENCE_LEVELS = [
  "less_than",
  "less_than_or_equal"
] as const;
export const COMMON_OPTIONS_SSN_CONFIDENCE_LEVELS = {
  2: "Low",
  3: "Medium",
  4: "High"
} as const;
export const COMMON_OPTIONS_LESSER_SSN_CONFIDENCE_LEVELS = {
  ...COMMON_OPTIONS_SSN_CONFIDENCE_LEVELS,
  5: "Very high"
} as const;

export const COMMON_OPTIONS_GREATER_SSN_CONFIDENCE_LEVELS = {
  ...COMMON_OPTIONS_SSN_CONFIDENCE_LEVELS,
  1: "Very low"
} as const;

export const ALL_OPTIONS_SSN_CONFIDENCE_LEVELS = {
  1: "Very low",
  ...COMMON_OPTIONS_SSN_CONFIDENCE_LEVELS,
  5: "Very high"
} as const;

export const NOT_STARTED_OR_IN_PROGRESS = [
  "Not yet started",
  "Started",
  undefined
] as const;

export const AVAILABLE_WORKFLOW_STAGES = [
  PHASE_APPLICATION,
  PHASE_UNDERWRITING,
  PHASE_SCORECARDS,
  PHASE_PLACEMENT,
  PHASE_OFFER,
  PHASE_CLOSING,
  PHASE_FUNDED
];

export const WORKFLOW_RELATED_DEAL_STATUSES = [null, 1, 2, 3, 4, 5, 9];

export const WORKFLOW_STAGES = {
  APPLICATION: "application",
  UNDERWRITING: "underwriting",
  PLACEMENT: "placement",
  SCORECARDS: "scorecards",
  OFFER: "offer"
} as const;

export const MIDDESK_PHONE_ERROR_MESSAGE =
  "field contains an invalid number" as const;

export const WEEKDAYS_IDS = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 0
} as const;

export const OBFUSCATED_PREFIX = "*****";

export const YES_NO_OPTIONS = { 1: "Yes", 0: "No" } as const;
export const DISCOVERY_FILTER_OPTIONS = {
  0: "Incomplete",
  1: "Complete"
} as const;

export const NEWS_SECTION_TITLE = "News";
export const DAYS_IN_MONTH = 30;
export const DAYS_IN_YEAR = 365;
export const TIME_CONVERSION_SCALES: Record<TimeMeasure, number> = {
  days: 1,
  months: DAYS_IN_MONTH,
  years: DAYS_IN_YEAR
} as const;

export const CLEAR_RISK_INFORM_PERSON_REPORT_SECTIONS = {
  RISK_INFORM_SCORE: "RiskInformScore",
  RISK_INFORM_SUMMARY: "RiskInformSummary"
} as const;

export const INTEGRATION_SERVICES = {
  MCA_TRACK: "MCA Track",
  AIRWALLEX: "Airwallex",
  LITHIC: "Lithic",
  CANOPY: "Canopy"
} as const;

export const ATTRIBUTES_VIEW_ATTRIBUTES_TAB = "attributes";
export const CLIENT_TRACKING_TOKEN = "client_tracking_token";
export const CLIENT_TRACKING_TOKENS = "client_tracking_tokens";
export const ADDITIONAL_TRACKING_TOKENS = "additional_tracking_tokens";
export const ATTRIBUTES_VIEW_OFFER_TEMPLATES_TAB = "offerTemplates";
export const DAB = "D&B";

export const NA = "N/A";

export const PII_MAPPING = {
  ABN: "Australia Business Number",
  DLN: "Drivers License Number",
  BN: "Business Number",
  SIN: "The Social Insurance Number",
  EIN: "Employer Identification Number",
  SSN: "Social Security Number",
  AN: "Account Number",
  RN: "Routing Number",
  ITIN: "Individual Taxpayer Identification Number"
};
export const TEXT_CHARACTER_LIMIT_DEFAULT = 150 as const;
export const TEXT_CHARACTER_LIMIT_MEDIUM = 255 as const;

export const ATTRIBUTE_SYMBOLS: Record<string, string> = {
  [TYPE_LESS_THAN]: "<",
  [TYPE_LESS_THAN_OR_EQUAL]: "<=",
  [TYPE_GREATER_THAN]: ">",
  [TYPE_GREATER_THAN_OR_EQUAL]: ">=",
  [TYPE_EQUAL]: "=",
  [TYPE_NOT_EQUAL]: "!=",
  [TYPE_IN]: "in",
  [TYPE_NOT_IN]: "not in"
};

export const SERVICE_APPLICATION_DATA = "Application Data" as const;
export const SERVICE_APPLICATION = "application" as const;
export const SERVICE_CUSTOM = "custom" as const;
export const SERVICE_CUSTOM_ATTRIBUTES = "Custom Attributes" as const;
export const SERVICE_TRIGGER = "Trigger" as const;
export const CUSTOM_ATTRIBUTE_SCHEMA_VERSION = "1.1.0" as const;
export const CUSTOM_ATTRIBUTE_V2_SCHEMA_VERSION = "2.3.0" as const;
export const TIME_IN_BUSINESS_SERVICE_EXCEPTIONS = [
  "middesk_time_in_business",
  "dnb_ci_l2_time_in_business",
  "lexis_nexis_corporate_filing_search_time_in_business",
  "application_time_in_business"
] as const;

export const PROVIDER_IMAGES = [
  {
    service: "Ekata",
    provider_id: 1,
    filename: "ekata.svg"
  },
  {
    service: "Sentilink",
    provider_id: 2,
    filename: "sentilink.svg"
  },
  {
    service: "Experian",
    provider_id: 3,
    filename: "experian.svg"
  },
  {
    service: "Plaid",
    provider_id: 4,
    filename: "plaid.svg"
  },
  {
    service: "Ocrolus",
    provider_id: 5,
    filename: "ocrolus.svg"
  },
  {
    service: "Middesk",
    provider_id: 6,
    filename: "middesk.svg"
  },
  {
    service: "Dun & Bradstreet",
    provider_id: 7,
    filename: "dnb.svg"
  },
  {
    service: "Dnb",
    provider_id: 7,
    filename: "dnb.svg"
  },
  {
    service: "Scorely",
    provider_id: 8,
    filename: "scorely.svg"
  },
  {
    service: "Socure",
    provider_id: 9,
    filename: "socure.svg"
  },
  {
    service: "Heron",
    provider_id: 10,
    filename: "heron.svg"
  },
  {
    service: "Persona",
    provider_id: 11,
    filename: "persona.svg"
  },
  {
    service: "Railz",
    provider_id: 12,
    filename: "railz.svg"
  },
  {
    service: "Ntropy",
    provider_id: 13,
    filename: "ntropy.svg"
  },
  {
    service: "Clear",
    provider_id: 14,
    filename: "clear.svg"
  },
  {
    service: "Inscribe",
    provider_id: 15,
    filename: "inscribe.svg"
  },
  {
    service: "Codat",
    provider_id: 16,
    filename: "codat.svg"
  },
  {
    service: "Lexis",
    provider_id: 17,
    filename: "lexisnexis.svg"
  },
  {
    service: "Lexis",
    provider_id: 18,
    filename: "lexisnexis.svg"
  },
  {
    service: "Lexis",
    provider_id: 21,
    filename: "lexisnexis.svg"
  },
  {
    service: "Lexis",
    provider_id: 34,
    filename: "lexisnexis.svg"
  },
  {
    service: "Lexis",
    provider_id: 35,
    filename: "lexisnexis.svg"
  },
  {
    service: "Lexis Nexis",
    provider_id: 36,
    filename: "lexisnexis.svg"
  },
  {
    service: "Equifax",
    provider_id: 37,
    filename: "equifax.svg"
  },
  {
    service: "Equifax",
    provider_id: 43,
    filename: "equifax.svg"
  },
  {
    service: "MoneyThumb",
    provider_id: 45,
    filename: "moneythumb.svg"
  },
  {
    service: "Experian BOP Premier",
    provider_id: 47,
    filename: "experian.svg"
  },
  {
    service: "Experian BOP",
    provider_id: 48,
    filename: "experian.svg"
  },
  {
    service: "bluCognition",
    provider_id: 49,
    filename: "blucognition.svg"
  },
  {
    service: "Tax Status",
    provider_id: 50,
    filename: "taxstatus.svg"
  },
  {
    service: "Enigma",
    provider_id: 51,
    filename: "enigma.svg"
  },
  {
    service: "TransUnion",
    provider_id: 52,
    filename: "transunion.svg"
  },
  {
    service: "Lexis Nexis",
    provider_id: 53,
    filename: "lexisnexis.svg"
  },
  {
    service: "Application Data",
    provider_id: "Application Data",
    filename: "other.svg"
  },
  {
    service: SERVICE_CUSTOM_ATTRIBUTES,
    provider_id: SERVICE_CUSTOM_ATTRIBUTES,
    filename: "custom_attribute.svg"
  },
  {
    service: "Formula Function",
    provider_id: "function",
    filename: "function.svg"
  }
] as const;

export const EMPTY_RESOURCE = {
  data: [],
  meta: {
    page: 1,
    total: 0
  },
  links: {}
} as const;

export const APPLICATION_SOURCE = {
  API: 1,
  WIDGET: 2,
  RENEWAL: 3,
  IMPORT: 4,
  DASHBOARD: 5
} as const;

export const DEFAULT_PAGE_TITLE = "Lendflow" as const;

export const APPLICATION_LINK_TYPE_CONTRACT = "contractTemplate" as const;

export const DEFAULT_LOOKBACK_PERIOD = [6, 1];

export const RESTRICTION_TYPE_PRIMARY_BANK_ACCOUNT =
  "primary_bank_accounts" as const;

export const DEFAULT_WIDGET_TYPE = "1" as const;

export const CONTRACT_TYPES_NAMES: Record<ContractType, string> = {
  equipment_share: "Equipment Share",
  equipment_rental: "Equipment Rental",
  erc_full: "ERC Full",
  erc_csa_flat: "ERC CSA Flat",
  sale_of_future_receipts: "Sale of Future Receipts",
  marketplace: "Marketplace"
} as const;

export const WYSIWYG_TOOLBAR = [
  ["bold", "underline", "italic"],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"]
] as const;

export const WYSIWYG_TOOLBAR_LINE_HEIGHT_OPTIONS = [
  "",
  "13px",
  "16px",
  "20px",
  "21px",
  "23px",
  "26px",
  "31px",
  "36px",
  "39px",
  "52px",
  "65px"
] as const;

export const PARAGRAPH_MARGIN_OPTIONS = [
  "",
  "0px",
  "5px",
  "8px",
  "10px",
  "12px",
  "16px",
  "20px",
  "24px",
  "28px",
  "32px"
] as const;

export const WYSIWYG_TOOLBAR_DYNAMIC = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  ["bold", "underline", "italic", "strike"],
  [{ color: [] }, { script: "sub" }, { script: "super" }],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
  [
    {
      lineheight: WYSIWYG_TOOLBAR_LINE_HEIGHT_OPTIONS
    },
    {
      marginTop: PARAGRAPH_MARGIN_OPTIONS
    },
    {
      marginBottom: PARAGRAPH_MARGIN_OPTIONS
    }
  ]
] as const;

export const VALID_URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
export const DROPDOWN_WIDTHS = {
  max_width: 350, // max width of dropdown
  char_width: 7, // approx width of a character
  checkbox_item_padding: 79, // padding of checkbox dropdown item + scrollbar
  dropdown_padding: 35 // padding of dropdown list item + scrollbar
} as const;

export const NAICS_RANGE_DIGITS = {
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6
} as Record<number, number>;

export const DEFAULT_WORKFLOW_STATUS_COLOR = "#888e9d" as const;

export const FUZZY_SEARCH_THRESHOLD_DEFAULT = 0.4 as const;
